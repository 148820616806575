<template>
  <div class="coe">
    <!-- alertMsg -->
    <v-alert
      :type="type"
      v-if="type"
      style="z-index: 2; position: fixed; right: 0; left: 0; margin-left: 2%; margin-right: 2%;"
    >
      {{ alertMsg }}
    </v-alert>
    <!-- floating button [for add] -->
    <v-btn
      fab
      absolute
      class="plusButton"
      right
      bottom
      color="red accent-2"
      @click="addDialog = !addDialog"
    >
      <v-icon color="#fff">mdi-plus</v-icon>
    </v-btn>
    <!-- edit dialog box -->
    <v-dialog v-model="editDialog" max-width="800px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit COE
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-dialog
                ref="dialog"
                v-model="coeDateModal"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="editCoe.selectedCoeDate"
                    label="COE Date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="editCoe.selectedCoeDate" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="coeDateModal = false"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col>
              <v-select
                v-model="editCoe.selectedRound"
                :placeholder="editCoe.selectedRound"
                :items="roundList"
                item-value="id"
                item-text="name"
                label="COE Round"
              >
              </v-select>
            </v-col>
          </v-row>
          <hr />
          <hr />
          <!--  -->
          <v-row>
            <v-col
              md="2"
              sm="12"
              style="display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;"
            >
              <v-text style="font-weight: bold">Cat A:</v-text>
              <v-text style="font-weight: bold">Cat B:</v-text>
              <v-text style="font-weight: bold">Cat C:</v-text>
              <v-text style="font-weight: bold">Cat D:</v-text>
              <v-text style="font-weight: bold">Cat E:</v-text>
            </v-col>
            <v-col md="5" sm="12">
              <v-text-field
                v-model="editCoe.A.price"
                label="Price"
              ></v-text-field>
              <v-text-field
                v-model="editCoe.B.price"
                label="Price"
              ></v-text-field>
              <v-text-field
                v-model="editCoe.C.price"
                label="Price"
              ></v-text-field>
              <v-text-field
                v-model="editCoe.D.price"
                label="Price"
              ></v-text-field>
              <v-text-field
                v-model="editCoe.E.price"
                label="Price"
              ></v-text-field>
            </v-col>
            <v-col md="5" sm="12">
              <v-select
                v-model="editCoe.A.trend"
                :items="trendList"
                item-value="id"
                item-text="name"
                label="Trend"
              >
              </v-select>
              <v-select
                v-model="editCoe.B.trend"
                :items="trendList"
                item-value="id"
                item-text="name"
                label="Trend"
              >
              </v-select>
              <v-select
                v-model="editCoe.C.trend"
                :items="trendList"
                item-value="id"
                item-text="name"
                label="Trend"
              >
              </v-select>
              <v-select
                v-model="editCoe.D.trend"
                :items="trendList"
                item-value="id"
                item-text="name"
                label="Trend"
              >
              </v-select>
              <v-select
                v-model="editCoe.E.trend"
                :items="trendList"
                item-value="id"
                item-text="name"
                label="Trend"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="doEditCOE(editCoe)">Edit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--  -->

    <!-- delete dialog box -->
    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Confirmation
        </v-card-title>

        <v-card-text>
          <br />
          Are you sure you want to delete this coe data:
          <b>{{ delCoe.name }}</b
          >?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="doDelCoe(delCoe.id)">
            Yes
          </v-btn>
          <v-btn color="primary" text @click="deleteDialog = false">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Add COE
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="addCoe.code"
            :items="coeCategoryList"
            item-value="id"
            item-text="name"
            label="COE Category"
          >
          </v-select>
          <v-select
            v-model="addCoe.round"
            :items="roundList"
            item-value="id"
            item-text="name"
            label="COE Round"
          >
          </v-select>
          <v-text-field v-model="addCoe.price" label="Price"></v-text-field>
          <v-text-field v-model="addCoe.pqp" label="PQP"></v-text-field>
          <v-text-field v-model="addCoe.quota" label="Quota"></v-text-field>
          <v-text-field
            v-model="addCoe.bidsReceived"
            label="Bids Received"
          ></v-text-field>
          <v-select
            v-model="addCoe.trend"
            :items="trendList"
            item-value="id"
            item-text="name"
            label="Trend"
          >
          </v-select>

          <v-dialog
            ref="dialog"
            v-model="coeDateModal"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="addCoe.date"
                label="COE Date"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker :min="today" v-model="addCoe.date" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="coeDateModal = false"
                >OK</v-btn
              >
            </v-date-picker>
          </v-dialog>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="doAddCOE()">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table
      :headers="headers"
      :items="coeList"
      :page.sync="pagination.page"
      :items-per-page="pagination.limit"
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:item="row">
        <tr v-if="row.item.A != null">
          <td>{{ row.item.name }}</td>
          <td>{{ row.item.subName }}</td>
          <td v-if="row.item.A">
            ${{ row.item.A.price }} <b>{{ row.item.A.trend }}</b>
          </td>
          <td v-else>No Data</td>
          <td v-if="row.item.B">
            ${{ row.item.B.price }} <b>{{ row.item.B.trend }}</b>
          </td>
          <td v-else>No Data</td>
          <td v-if="row.item.C">
            ${{ row.item.C.price }} <b>{{ row.item.C.trend }}</b>
          </td>
          <td v-else>No Data</td>
          <td v-if="row.item.D">
            ${{ row.item.D.price }} <b>{{ row.item.D.trend }}</b>
          </td>
          <td v-else>No Data</td>
          <td v-if="row.item.E">
            ${{ row.item.E.price }} <b>{{ row.item.E.trend }}</b>
          </td>
          <td v-else>No Data</td>
          <!-- tbl row edit btn -->
          <td>
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="primary"
              @click="editButtonClick(row.item)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </td>
          <!-- tbl row del btn -->
          <td>
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="red"
              @click="delButtonClick(row.item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-pagination
      v-model="pagination.page"
      :circle="true"
      :disabled="false"
      :length="pagination.noOfPage"
      :page="pagination.page"
      update:page
      :total-visible="10000"
      @input="paginationChangeHandler"
    ></v-pagination>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

import getAxios from "../../../services/axios-get";
import postAxios from "../../../services/axios-post";
import putAxios from "../../../services/axios-put";
import deleteAxios from "../../../services/axios-delete";

Vue.use(VueAxios, axios);

export default {
  data() {
    return {
      alertMsg: null,
      type: null,
      editCoe: {
        A: {
          id: null,
          date: null,
          price: null,
          trend: null,
          round: null,
          coeCategoryCode: "A",
        },
        B: {
          id: null,
          date: null,
          price: null,
          trend: null,
          round: null,
          coeCategoryCode: "B",
        },
        C: {
          id: null,
          date: null,
          price: null,
          trend: null,
          round: null,
          coeCategoryCode: "C",
        },
        D: {
          id: null,
          date: null,
          price: null,
          trend: null,
          round: null,
          coeCategoryCode: "D",
        },
        E: {
          id: null,
          date: null,
          price: null,
          trend: null,
          round: null,
          coeCategoryCode: "E",
        },
        selectedRound: null,
        selectedCoeDate: new Date(),
      },
      delCoe: {
        id: null,
        name: "",
      },
      bumperSearch: "",
      bumperStatus: "",
      productURL: "",
      token: "",
      pageCount: 0,
      addDialog: false,
      deleteDialog: false,
      editDialog: false,
      coeDateModal: false,
      todayDate: "",
      pagination: {
        limit: 10,
        noOfPage: 1,
        page: 1,
      },
      headers: [
        { text: "Date", value: "name" },
        { text: "Round", value: "subName" },
        { text: "Cat A", value: "A" },
        { text: "Cat B", value: "B" },
        { text: "Cat C", value: "C" },
        { text: "Cat D", value: "D" },
        { text: "Cat E", value: "E" },
        { text: "Edit" },
        { text: "Delete" },
      ],
      coeList: [],
      coeCategoryList: [
        { id: "A", name: "Cat A" },
        { id: "B", name: "Cat B" },
        { id: "C", name: "Cat C" },
        { id: "D", name: "Cat D" },
        { id: "E", name: "Cat E" },
      ],
      roundList: [
        { id: 1, name: 1 },
        { id: 2, name: 2 },
      ],
      trendList: [
        { id: "up", name: "up" },
        { id: "down", name: "down" },
      ],
      addCoe: {
        code: "",
        date: "",
        price: 0,
        trend: "",
        round: 1,
        pqp: 0,
        quota: 0,
        bidsReceived: 0,
      },
    };
  },
  mounted() {
    this.fetchCredentials();
    this.fetchCoeList();
    this.fetchCoeCategory();
  },
  methods: {
    async delButtonClick(item) {
      console.log(item);
      const delIds = [];
      if (item.A) delIds.push(item.A.id);
      if (item.B) delIds.push(item.B.id);
      if (item.C) delIds.push(item.C.id);
      if (item.D) delIds.push(item.D.id);
      if (item.E) delIds.push(item.E.id);

      var self = this;

      self
        .doDeleteCOE(delIds)
        .then(res => {
          console.log(res);
          self.fetchCoeList();
          self.showAlert("success", "deleted successfully!");
        })
        .catch(e => {
          console.log(e);
        });
    },
    async doDeleteCOE(delIds) {
      var self = this;
      return new Promise((resolve, reject) => {
        let count = 0;
        try {
          delIds.forEach(async id => {
            try {
              const data = await deleteAxios(
                `${process.env.VUE_APP_SERVER_URL}/relatedDataSystem/delCoePriceRecord?coePriceRecordId=${id}`,
                self.token
              );
              console.log(data);
              console.log("waited");
              count++;
              if (count >= 5) {
                resolve();
              }
            } catch (e) {
              reject();
              console.log(e);
            }
          });
        } catch {
          reject();
        }
      });
    },
    editButtonClick(item) {
      console.log(item);
      this.editCoe.selectedCoeDate = item.field;
      this.editCoe.selectedRound = item.subName;
      this.editCoe.A.id = item.A ? item.A.id : null;
      this.editCoe.B.id = item.B ? item.B.id : null;
      this.editCoe.C.id = item.C ? item.C.id : null;
      this.editCoe.D.id = item.D ? item.D.id : null;
      this.editCoe.E.id = item.E ? item.E.id : null;
      this.editCoe.A.price = item.A ? item.A.price : null;
      this.editCoe.A.trend = item.A ? item.A.trend : null;
      this.editCoe.B.price = item.B ? item.B.price : null;
      this.editCoe.B.trend = item.B ? item.B.trend : null;
      this.editCoe.C.price = item.C ? item.C.price : null;
      this.editCoe.C.trend = item.C ? item.C.trend : null;
      this.editCoe.D.price = item.D ? item.D.price : null;
      this.editCoe.D.trend = item.D ? item.D.trend : null;
      this.editCoe.E.price = item.E ? item.E.price : null;
      this.editCoe.E.trend = item.E ? item.E.trend : null;
      this.editDialog = true;
    },
    paginationChangeHandler(pagination) {
      this.pagination.page = pagination;
      this.fetchCoeList();
    },
    async doEditCOE(editCoe) {
      const coeDate = new Date(editCoe.selectedCoeDate);
      const round = editCoe.selectedRound;
      editCoe.A.date = coeDate;
      editCoe.A.round = round;
      editCoe.B.date = coeDate;
      editCoe.B.round = round;
      editCoe.C.date = coeDate;
      editCoe.C.round = round;
      editCoe.D.date = coeDate;
      editCoe.D.round = round;
      editCoe.E.date = coeDate;
      editCoe.E.round = round;

      const params = [];
      params.push(editCoe.A);
      params.push(editCoe.B);
      params.push(editCoe.C);
      params.push(editCoe.D);
      params.push(editCoe.E);

      var self = this;
      putAxios(
        `${process.env.VUE_APP_SERVER_URL}/relatedDataSystem/updCoePriceRecords`,
        params,
        self.token
      )
        .then(res => {
          if (res.data) {
            self.editDialog = false;
            self.showAlert("success", "Coe updated!");
            self.fetchCoeList();
          }
        })
        .catch(e => {
          self.showAlert("error", e);
        });
    },
    async doAddCOE() {
      const self = this;
      try {
        const params = {
          coeCategoryCode: self.addCoe.code,
          date: self.addCoe.date,
          price: self.addCoe.price,
          trend: self.addCoe.trend,
          round: self.addCoe.round,
          status: "ACTIVE",
        };
        postAxios(
          `${process.env.VUE_APP_SERVER_URL}/relatedDataSystem/addCoePriceRecord`,
          params,
          self.token
        )
          .then(res => {
            if (res.data) {
              self.addDialog = false;
              self.fetchCoeList();
              self.showAlert("success", "New COE Added!");
            }
          })
          .catch(e => {
            self.showAlert("error", e);
          });
      } catch (e) {
        console.error(e);
      }
    },
    fetchCoeCategory() {
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/relatedContent/coeCategoryPair`,
        self.token
      ).then(res => {
        if (res.data) {
          self.coeCategoryList = res.data.data.content;
        }
      });
    },
    fetchCoeList() {
      const self = this;
      const params = {
        page: self.pagination.page - 1,
        size: self.pagination.limit,
      };
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/relatedDataSystem/coePriceRecord`,
        params,
        self.token
      ).then(res => {
        if (res.data) {
          self.coeList = res.data.data.content[0].fieldList;
          console.log(JSON.stringify(self.coeList));
          self.pagination.noOfPage = res.data.data.totalPages;
        }
      });
    },
    showAlert(type, message) {
      this.type = type;
      this.alertMsg = message;
      let timer = this.showAlert.timer;
      if (timer) {
        clearTimeout(timer);
      }
      this.showAlert.timer = setTimeout(() => {
        this.type = null;
      }, 3000);
      let t = this.showAlert.t;
      if (t) {
        clearInterval(t);
      }
    },
    fetchCredentials() {
      var self = this;
      const token = localStorage.getItem("jwt");
      if (token) {
        self.token = token;
      } else {
        self.showAlert("error", "unauthorized..");
      }
    },
  },
};
</script>
